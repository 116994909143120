<template>
    <div class="lossReportRecord-list">
        <app-list :opt="opt" @get="onGet" ref="myList"></app-list>
    </div>
</template>
<script>
    let validItemsName = (rule, value, callback) => {
        if (value == "" || value == undefined) {
            callback();
        } else {
            //仅支持输入数字、汉字，大小写字母，限制字符长度1~32个字符
            let reg = /^[a-zA-Z0-9_\u4e00-\u9fa5]{1,32}$/;
            if (!reg.test(value)) {
                callback(new Error("商品名称格式不对或超过32个字符"));
            } else {
                callback();
            }
        }
    };
    import cabinetUtil from "@utils/cabinetUtil.js"
    export default {
        data() {
            let _this = this;
            return {
                dialogVisible: false,
                opt: {
                    title: "报损记录",
                    search: [{
                        key: "date",
                        label: "报损时间",
                        type: "time-range"
                    }, {
                        key: "reportUserName",
                        label: "报损人",
                    }, {
                        key: "merchantId",
                        label: "商家名称",
                        type: 'remoteSearchById',
                        opt: {
                            list: [],
                            change(data) {
                                _this.getStoreList(data)
                            }
                        }
                    }, {
                        key: "storeName",
                        label: "门店名称",
                        type: "remoteSearchById",
                        opt: {
                            list: []
                        }
                    }, {
                        key: "damageItemName",
                        label: "商品名称",
                        rules: [{
                            validator: validItemsName,
                            trigger: ['blur']
                        }]
                    }, {
                        key: "id",
                        label: "报损单号",
                    }],
                    columns: [{
                            label: "报损单号",
                            key: "id",
                        }, {
                            label: "报损人",
                            key: "reportUserName",
                            align: "center"
                        },
                        {
                            label: "报损人手机号",
                            key: "reportUserPhone",
                            align: "center"
                        },
                        {
                            label: "商家名称",
                            key: "merchantName"
                        },
                        {
                            label: "门店信息",
                            key: "storeName",
                            align: "center"
                        },
                        {
                            label: "报损商品名称",
                            key: "itemName",
                            align: "center"
                        },
                        {
                            label: "报损数量",
                            key: "damageNum",
                            align: "center"
                        },
                        {
                            label: "报损理由",
                            key: "damageReason",
                            align: "center"
                        },
                        {
                            label: "报损时间",
                            key: "createdTime",
                            align: "center"
                        },
                        {
                            label: "操作",
                            key: "action",
                            type: "action-but",
                            align: "center",
                            opt: {
                                list: [{
                                    label: "详情",
                                    on(row) {
                                        _this.$router.push({
                                            path: "/main/lossReportRecord/detail",
                                            query: {
                                                id: row.id,
                                            }
                                        })
                                    }
                                }]
                            }
                        }
                    ],
                },
                merchantFlag: false,
            };
        },
        created() {
            console.log("lossReportRecord-list created!!");

        },
        activated() {
            console.log("lossReportRecord-list activated!!");
            this.merchantFlag = this.getLoginType('loginType');
            if (this.merchantFlag) {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        this.opt.search.splice(index, 1);
                    }
                })
                this.opt.columns.forEach((item, index) => {
                    if (item.key == 'merchantName') {
                        this.opt.columns.splice(index, 1);
                    }
                })
            } else {
                this.opt.search.forEach((item, index) => {
                    if (item.key == 'merchantId') {
                        cabinetUtil.getMerchantList().then(res => {
                            item.opt.list = res;
                        })
                    }
                })
            }
        },

        methods: {
            onGet(opt) {
                let dto = {
                    pageNum: opt.skip,
                    pageSize: opt.limit,
                    createdTimeBegin: opt.searchForm['date'] ? this.format(opt.searchForm['date'][0]) : null,
                    createdTimeEnd: opt.searchForm['date'] ? this.format(opt.searchForm['date'][1]) : null,
                    ...opt.searchForm
                };
                this.post("mall-service/itemsDamageRecord/page/query", dto, {
                    isUseResponse: true
                }).then(res => {
                    res.data.data = res.data.list;
                    res.data.list.forEach(item => {
                        var itemNameList = [];
                        if (item.itemsDamageResps) {
                            item.itemsDamageResps.forEach((it, idx) => {
                                itemNameList.push(it.itemsName)
                            })
                            item.itemName = itemNameList.length > 0 ? itemNameList.join('、') : '--';
                        }
                        if(item.storeName) {
                            if (item.storeName.length > 7) {
                                item.storeName = item.storeName.substr(0, 7) + '...';
                            }
                        }
                        if (item.itemName) {
                           if (item.itemName.length > 4) {
                                item.itemName = item.itemName.substr(0, 4) + '...';
                            } 
                        }
                        item.merchantName = item.merchantName ? item.merchantName : '--';
                    });
                    opt.cb(res.data);
                });
                if(opt.searchForm.merchantId) {
                    this.getStoreList(opt.searchForm.merchantId);
                }else{
                    this.getStoreList();
                }
            },
            //获取门店列表
            getStoreList(data) {
                let dto = {
                    currentPage: 1,
                    size: 99999,
                };
                if(data) {
                    dto.merchantId = data
                }
                this.post("/mall-service/v1/counterStores/getAllList", dto, {
                    isUseResponse: true,
                }).then(res => {
                    let storeList = res.data.data;
                    for (var i = 0; i < storeList.length; i++) {
                        for (var j = i + 1; j < storeList.length; j++) {
                            if (storeList[i].groupName == storeList[j].groupName) {
                                storeList.splice(j,1)
                            }
                        }
                    }
                    storeList = JSON.parse(JSON.stringify(storeList).replace(/groupName/g, "value"));
                    this.opt.search.forEach(item => {
                        if (item.key == 'storeName') {
                        item.opt.list = storeList;
                        }
                    })
                });
            },
        }
    };
</script>
<style lang="scss">

</style>